import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import { activateAuthLayout } from '../../../../store/actions';
import { connect } from 'react-redux';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { capitalizeStr } from '../../../../helpers/common';
import ColumnHeadersTooltip from '../../../../components/ColumnHeadersTooltip';
import env from '../../../../env';

const GET_DATA = gql`
  query getData($ProductId: ID) {
    Product(ProductId: $ProductId) {
      ProductId
      Name
      CatId
      Active
      ArchivedInXero
      ProductCategory {
        CatId
        Name
      }
      SyncWithXero
      JarvisMagicPricing
      ShownInHardwareTable
      XeroTrackedAsInventory
      XeroStockCode
      XeroInventoryAssetAccountCode
      XeroCOA
      XeroAccount {
        Account
      }
      XeroCOASales
      XeroAccountSales {
        Account
      }
      WeightGrams
      ProductPrices {
        ProductPriceId
        PriceLineId
        Price
        Multiplier
        QuoteTypeId
        PriceType
        QantasPointsApplicable
        Description
        isDisplayedPrice
        DisplayProduct
        IncludePriceInTotal
      }
      ProductRates {
        RateId
        RateLineId
        ProductId
        RateType
        RateUnits
        Rate
        QuoteTypeId
      }
      ZapierQuoted
      ZapierQuotedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
      ZapierOrdered
      ZapierOrderedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
      ZapierModified
      ZapierModifiedEvent {
        ZapierEventId
        ZapName
        ZapAPIUrl
      }
    }

    ProductCategories {
      CatId
      Name
    }

    XeroCOA {
      XeroCOAId
      Account
    }

    QuoteTypes {
      QuoteTypeId
      Name
      Description
      DefaultMultiplier
      Active
    }

    ZapierEvents {
      ZapierEventId
      ZapName
      ZapAPIUrl
    }
  }
`;

const CREATE_PRODUCT = gql`
  mutation createProduct($ProductInput: ProductInput) {
    createProduct(input: $ProductInput) {
      ProductId
      Name
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation updateProduct($ProductInput: ProductInput) {
    updateProduct(input: $ProductInput) {
      ProductId
      Name
      ProductCategory {
        CatId
        Name
      }
      XeroStockCode
      SyncWithXero
      ArchivedInXero
      JarvisMagicPricing
      ShownInHardwareTable
      XeroTrackedAsInventory
      WeightGrams
      ProductPrices {
        ProductPriceId
        Price
        QuoteTypeId
        isDisplayedPrice
        DisplayProduct
        IncludePriceInTotal
        Multiplier
        PriceType
        QantasPointsApplicable
      }
      ZapierQuoted
      ZapierOrdered
      ZapierModified
    }
  }
`;

const CREATE_PRODUCT_RATES = gql`
  mutation createProductRates(
    $ProductRatesList: [ProductRatesList]
    $ProductId: String
  ) {
    createProductRates(input: $ProductRatesList, ProductId: $ProductId) {
      RateId
      ProductId
      RateType
      RateUnits
      Rate
      QuoteTypeId
    }
  }
`;

const CREATE_PRODUCT_PRICES = gql`
  mutation createProductPrices(
    $ProductPricesList: [ProductPricesList]
    $ProductId: String
  ) {
    createProductPrices(input: $ProductPricesList, ProductId: $ProductId) {
      ProductPriceId
      ProductId
      PriceType
      Price
      Multiplier
      QantasPointsApplicable
      QuoteTypeId
      Description
      isDisplayedPrice
      DisplayProduct
      IncludePriceInTotal
    }
  }
`;

const PRICE_TYPES = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Hardware', value: 'hardware' },
  { label: 'Setup', value: 'setup' },
];

const RATE_TYPES = [
  { label: 'Local Calls', value: 'LOCAL_CALLS' },
  { label: 'National Calls', value: 'NATIONAL_CALLS' },
  { label: 'Mobile Calls', value: 'MOBILE_CALLS' },
  { label: '1300 Calls', value: '_1300_CALLS' },
  { label: '1800 Calls', value: '_1800_CALLS' },
  { label: 'Mobile Data Overage', value: 'MOBILE_DATA_OVERAGE' },
  { label: 'Data Overage', value: 'DATA_OVERAGE' },
];
const RATE_UNITS = [
  { label: 'Unlimited', value: 'UNLIMITED' },
  { label: 'Flat', value: 'FLAT' },
  { label: 'Per Minute', value: 'PER_MINUTE' },
  { label: 'Per GB', value: 'PER_GB' },
];

const PRODUCT_PRICING_COLUMNS = [
  {
    id: 1,
    field: 'Description',
    label: 'Item',
    description: 'Product Pricing Desc',
  },
  {
    id: 2,
    field: 'PriceType',
    label: 'Price Type',
    description: 'Product Pricing Desc',
  },
  {
    id: 3,
    field: 'QantasPointsApplicable',
    label: 'Qantas Points',
    description: 'Qantas Points Desc',
    visible: parseInt(env.QANTAS_POINTS_ENABLED) === 1 ? true : false,
  },
  {
    id: 4,
    field: 'Price',
    label: 'Cost Quote',
    description: 'Price for Cost type of quote',
  },
  {
    id: 9,
    field: 'Multiplier',
    label: 'CAPEX Multiplier',
    description: 'Multiplier for CAPEX price quote',
  },
  {
    id: 6,
    field: 'Price',
    label: 'CAPEX Quote',
    description: 'Price for CAPEX type of quote',
  },
  {
    id: 5,
    field: 'Price',
    label: 'OPEX Quote',
    description: 'Price for OPEX type of quote',
  },
  {
    id: 7,
    field: 'Price',
    label: 'QANTAS Quote',
    description: 'Price for QANTAS type of quote',
  },
  {
    id: 8,
    field: 'Price',
    label: 'ENERDS Quote',
    description: 'Price for ENERDS type of quote',
  },
];

const ProductForm = (props) => {
  props.activateAuthLayout();

  const location = useLocation();

  // initialize state
  const currentQuoteParam =
    location.search && location.search.includes('?currentQuote=')
      ? `?currentQuote=${location.search.split('=').pop()}`
      : '';
  const [formType] = useState(
    location.pathname.match(/\/edit\/[\w\d\W]*/g) ? 'edit' : 'create'
  );
  const [productData, setProductData] = useState({});
  const [quoteTypes, setQuoteTypes] = useState({});
  const [productPrices, setProductPrices] = useState([]);
  const [newPrice, setNewPrice] = useState({
    Description: '',
    PriceType: PRICE_TYPES[0].value,
    QantasPointsApplicable: 0,
    CostPrice: '',
    Multiplier: '',
    OpexPrice: '',
    CapexPrice: '',
    QantasPrice: '',
    EnerdsPrice: '',
    isDisplayedPrice: '',
    DisplayProduct: '',
    IncludePriceInTotal: '',
  });
  const [productRates, setProductRates] = useState([]);
  const [init, setInit] = useState(false);
  const [newRate, setNewRate] = useState({
    RateType: RATE_TYPES[0].value,
    OpexRate: '',
    CapexRate: '',
    QantasRate: '',
    EnerdsRate: '',
    RateUnits: "",
  });
  const [formResult, setFormResult] = useState(null);
  const [qantasType, setQantasType] = useState(null);
  const [zapEventsOptions, setZapEventsOptions] = useState([]);

  // initialize apollo graphql queries and mutations
  const { data, refetch } = useQuery(GET_DATA, {
    variables: { ProductId: props.pathArg ? props.pathArg : 0 },
  });
  const [createProduct, { data: createdProductData }] =
    useMutation(CREATE_PRODUCT);
  const [updateProduct, { data: updatedProductData }] =
    useMutation(UPDATE_PRODUCT);
  const [createProductRates, { data: createdProductRatesData }] =
    useMutation(CREATE_PRODUCT_RATES);
  const [createProductPrices, { data: createdProductPricesData }] = useMutation(
    CREATE_PRODUCT_PRICES
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      createdProductData &&
      createdProductData.createProduct &&
      formResult === null &&
      (createdProductRatesData || createdProductPricesData)
    ) {
      setFormResult({
        success: true,
        message: 'Successfully created a new product',
      });
    }

    if (
      updatedProductData &&
      updatedProductData.updateProduct &&
      formResult === null &&
      (createdProductRatesData || createdProductPricesData)
    ) {
      setFormResult({
        success: true,
        message: 'Successfully updated product',
      });
    }

    if (data && data.Product && init === false) {
      // initialize product details and prices/rates
      setProductData({
        ProductId: data.Product.ProductId,
        Name: data.Product.Name,
        XeroStockCode: data.Product.XeroStockCode,
        SyncWithXero:
          data.Product.ArchivedInXero === 1 ? 0 : data.Product.SyncWithXero,
        JarvisMagicPricing: data.Product.JarvisMagicPricing,
        ShownInHardwareTable: data.Product.ShownInHardwareTable,
        XeroCOA: data.Product.XeroCOA,
        XeroCOASales: data.Product.XeroCOASales,
        WeightGrams: data.Product.WeightGrams,
        CatId: data.Product.CatId,
        ZapierQuoted: data.Product.ZapierQuoted,
        ZapierOrdered: data.Product.ZapierOrdered,
        XeroInventoryAssetAccountCode:
          data.Product.XeroInventoryAssetAccountCode,
        Active: data.Product.ArchivedInXero === 1 ? 0 : data.Product.Active,
        ArchivedInXero: data.Product.ArchivedInXero,
      });

      data.QuoteTypes.forEach((price_type) => {
        quoteTypes[price_type.Name] = price_type;
        setQuoteTypes(quoteTypes);
      });

      const productRates = transformDBDataToProductRates();
      setProductRates(productRates);
      const productPrices = transformDBDataToProductPrices();
      setProductPrices(productPrices);

      // add the default product name to product rate description
      const rate = Object.assign({}, newRate);
      setNewRate(rate);

      // add the default product name to product price description
      const price = Object.assign({}, newPrice);
      price.Description = data.Product.Name;
      setNewPrice(price);
      setInit(true);
    } else if (formType.toLowerCase() === 'create' && init === false) {
      setProductData({
        Active: true,
        ShownInHardwareTable: true,
      });
      setInit(true);
    }

    if (data && zapEventsOptions.length === 0) {
      setZapEventsOptions([
        { value: null, label: 'No Zap' },
        ...data.ZapierEvents.map((zap) => ({
          value: zap.ZapierEventId,
          label: zap.ZapName,
        })),
      ]);
    }

    if (data && qantasType === null) {
      const qantas = data.QuoteTypes.filter(
        (quoteType) => quoteType.Name === 'QANTAS'
      );
      if (qantas.length > 0) setQantasType(qantas[0]);
    }
  });

  useEffect(() => {
    refetch();
  }, []);

  const productDetailsChange = (e, field) => {
    const value = e.target ? e.target.value : e.value; // check if field value is from input or dropdown
    const product = Object.assign({}, productData);
    if (
      field === 'Active' ||
      field === 'SyncWithXero' ||
      field === 'XeroTrackedAsInventory' ||
      field === 'JarvisMagicPricing' ||
      field === 'ShownInHardwareTable' ||
      field === 'ArchivedInXero'
    ) {
      product[field] = e;

      if (field === 'ArchivedInXero' && e === 1) {
        product['Active'] = 0;
        product['SyncWithXero'] = 0;
      } else {
        product['Active'] = 1;
        if (productData.JarvisMagicPricing) {
          product['SyncWithXero'] = 1;
        }
      }

      if (field === 'JarvisMagicPricing' && e !== 1) {
        product['SyncWithXero'] = 0;
      }
    } else product[field] = value;
    setProductData(product);
    if (field === 'Name') {
      const rate = Object.assign({}, newRate);
      setNewRate(rate);

      const price = Object.assign({}, newPrice);
      price.Description = value;
      setNewPrice(price);
    }
  };

  const computeQuoteTypePrices = (costPrice) => {
    const price = Object.assign({}, newPrice);
    price.CostPrice = costPrice;
    price.CapexPriceStyle = price.CapexPriceChanged
      ? { width: '100px' }
      : { background: '#ffefc1', width: '100px' };
    price.OpexPriceStyle = price.OpexPriceChanged
      ? { width: '100px' }
      : { background: '#ffefc1', width: '100px' };
    price.EnerdsPriceStyle = price.EnerdsPriceChanged
      ? { width: '100px' }
      : { background: '#ffefc1', width: '100px' };

    price.CapexPrice = price.CapexPriceChanged
      ? price.CapexPrice
      : (
        (costPrice ? costPrice : 0) * quoteTypes.CAPEX.DefaultMultiplier
      ).toFixed(2);
    price.OpexPrice = price.OpexPriceChanged
      ? price.OpexPrice
      : (price.CapexPrice / 24).toFixed(2);
    price.EnerdsPrice = price.EnerdsPriceChanged
      ? price.EnerdsPrice
      : (price.OpexPrice * quoteTypes.ENERDS.DefaultMultiplier).toFixed(2);

    setNewPrice(price);
  };

  const updatePriceEntry = (value, index, field, computations) => {
    const editPrice = Object.assign({}, productPrices[index]);
    const newPrices = Array.from(productPrices);

    editPrice[field] = value;

    let CapexMultiplier = editPrice.Multiplier
      ? editPrice.Multiplier
      : quoteTypes.CAPEX.DefaultMultiplier
        ? quoteTypes.CAPEX.DefaultMultiplier
        : 2;

    let EnerdsDefaultMultiplier =
      quoteTypes.ENERDS && quoteTypes.ENERDS.DefaultMultiplier
        ? quoteTypes.ENERDS.DefaultMultiplier
        : 4;

    editPrice[field] = value;

    editPrice[field + 'Style'] = { width: '100px' };
    if (productData.JarvisMagicPricing) {
      if (
        [
          'CostPrice',
          'Multiplier',
          'CapexPrice',
          'OpexPrice',
          'EnerdsPrice',
        ].includes(field)
      ) {
        if (field !== 'CostPrice') {
          if (field !== 'Multiplier') {
            editPrice.MultiplierStyle = {
              background: '#ffefc1',
              width: '100px',
            };
            editPrice.Multiplier = value / editPrice.CostPrice;
          } else {
            editPrice.CapexPriceStyle = {
              background: '#ffefc1',
              width: '100px',
            };
            editPrice.CostPriceStyle = { width: '100px' };
            editPrice.CapexPrice = editPrice.CostPrice * value;
          }
        } else {
          editPrice.MultiplierStyle = { width: '100px' };
          editPrice.CapexPrice = value * CapexMultiplier;
          editPrice.Multiplier = CapexMultiplier;
        }

        if (field !== 'CapexPrice') {
          editPrice.CapexPriceStyle = { background: '#ffefc1', width: '100px' };
        }

        if (productData.JarvisMagicPricing) {
          editPrice.OpexPrice = 0;
          editPrice.EnerdsPrice = 0;
          productPrices.forEach((price, index) => {
            if (price.PriceType === 'monthly') {
              price.CapexPrice = '';
              price.OpexPriceStyle = { background: '#ffefc1', width: '100px' };
              price.OpexPrice = (editPrice.CapexPrice / 24).toFixed(2);
              price.EnerdsPriceStyle = {
                background: '#ffefc1',
                width: '100px',
              };
              price.EnerdsPrice = (
                price.OpexPrice * EnerdsDefaultMultiplier
              ).toFixed(2);
              newPrices[index] = price;
            }
          });
        } else {
          if (field !== 'OpexPrice') {
            editPrice.OpexPriceStyle = {
              background: '#ffefc1',
              width: '100px',
            };
            editPrice.OpexPrice = (editPrice.CapexPrice / 24).toFixed(2);
          }

          if (field !== 'EnerdsPrice') {
            editPrice.EnerdsPriceStyle = {
              background: '#ffefc1',
              width: '100px',
            };
            editPrice.EnerdsPrice = (
              editPrice.OpexPrice * EnerdsDefaultMultiplier
            ).toFixed(2);
          }
        }
      }
    }

    newPrices[index] = editPrice;
    setProductPrices(newPrices);
  };

  const onNewPriceChange = (value, field, computations) => {
    const price = Object.assign({}, newPrice);

    /*price[field] = value;
    if(field === 'Capex') {
      price.CapexPriceChanged = true;
      price.CapexPriceStyle = { width: '100px' };
    }
    if(field === 'Enerds') {
      price.EnerdsPriceChanged = true;
      price.EnerdsPriceStyle = { width: '100px' };
    }
    if(field === 'Opex') {
      price.OpexPriceChanged = true;
      price.OpexPriceStyle = { width: '100px' };
    }
    setNewPrice(price);

    field === 'CostPrice' && computeQuoteTypePrices(value);*/

    let CapexDefaultMultiplier =
      quoteTypes.CAPEX && quoteTypes.CAPEX.DefaultMultiplier
        ? quoteTypes.CAPEX.DefaultMultiplier
        : 2;

    let CapexMultiplier;

    if (price.Multiplier) {
      CapexMultiplier = price.Multiplier;
    } else if (!CapexMultiplier && !price.Multiplier) {
      CapexMultiplier = CapexDefaultMultiplier;
    } else {
      CapexMultiplier = 1;
    }

    let EnerdsDefaultMultiplier =
      quoteTypes.ENERDS && quoteTypes.ENERDS.DefaultMultiplier
        ? quoteTypes.ENERDS.DefaultMultiplier
        : 4;

    price[field] = value;

    price[field + 'Style'] = { width: '100px' };
    if (productData.JarvisMagicPricing) {
      if (
        [
          'CostPrice',
          'Multiplier',
          'CapexPrice',
          'OpexPrice',
          'EnerdsPrice',
        ].includes(field)
      ) {
        if (field !== 'CostPrice') {
          if (field !== 'Multiplier') {
            price.MultiplierStyle = { background: '#ffefc1', width: '100px' };
            price.Multiplier = value / price.CostPrice;
          } else {
            price.CapexPriceStyle = { background: '#ffefc1', width: '100px' };
            price.CostPriceStyle = { width: '100px' };
            price.CapexPrice = price.CostPrice * value;
          }
        } else {
          price.MultiplierStyle = { width: '100px' };
          price.CapexPrice = value * CapexMultiplier;
          price.Multiplier = CapexMultiplier;
        }

        if (field !== 'CapexPrice') {
          price.CapexPriceStyle = { background: '#ffefc1', width: '100px' };
        }

        if (productData.JarvisMagicPricing) {
          price.OpexPrice = 0;
          price.EnerdsPrice = 0;
        } else {
          if (field !== 'OpexPrice') {
            price.OpexPriceStyle = { background: '#ffefc1', width: '100px' };
            price.OpexPrice = (price.CapexPrice / 24).toFixed(2);
          }

          if (field !== 'EnerdsPrice') {
            price.EnerdsPriceStyle = { background: '#ffefc1', width: '100px' };
            price.EnerdsPrice = (
              price.OpexPrice * EnerdsDefaultMultiplier
            ).toFixed(2);
          }
        }
      }
    }

    setNewPrice(price);
  };

  const onDeletePrice = (index) => {
    const newPrices = Array.from(productPrices);
    newPrices.splice(index, 1);
    setProductPrices(newPrices);
  };

  const addProductPrice = (e) => {
    e.preventDefault();
    const prices = Array.from(productPrices);
    newPrice.PriceLineId =
      productPrices.length > 0
        ? parseInt(productPrices.pop().PriceLineId) + 1
        : 1;
    data.QuoteTypes.forEach((quoteType) => {
      const quoteTypeName =
        quoteType.Name === 'COST_PRICE'
          ? 'Cost'
          : capitalizeStr(quoteType.Name);
      newPrice[`${quoteTypeName}Price`] = newPrice[`${quoteTypeName}Price`]
        ? newPrice[`${quoteTypeName}Price`]
        : 0;
    });
    newPrice.QantasPointsApplicable = newPrice.PriceType === 'monthly' ? 1 : 0;
    prices.push(newPrice);
    setProductPrices(prices);
    setNewPrice({
      Description: productData.Name,
      PriceType: PRICE_TYPES[0].value,
      QantasPointsApplicable: 0,
      CostPrice: '',
      Multiplier: '',
      OpexPrice: '',
      isDisplayedOpexPrice: '',
      CapexPrice: '',
      isDisplayedCapexPrice: '',
      QantasPrice: '',
      isDisplayedQantasPrice: '',
      EnerdsPrice: '',
      isDisplayedEnerdsPrice: '',
    });
  };

  const transformDBDataToProductPrices = () => {
    let transformedProductPrices = [];
    if (data && data.Product && data.Product.ProductPrices.length > 0) {
      const prices = data.Product.ProductPrices;
      const costId = parseInt(
        data.QuoteTypes.filter(
          (quoteType) => quoteType.Name === 'COST_PRICE'
        )[0].QuoteTypeId
      );
      const opexId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'OPEX')[0]
          .QuoteTypeId
      );
      const capexId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'CAPEX')[0]
          .QuoteTypeId
      );
      const qantasId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'QANTAS')[0]
          .QuoteTypeId
      );
      const enerdsId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'ENERDS')[0]
          .QuoteTypeId
      );

      const priceLines = [
        ...new Set(prices.map((price) => parseInt(price.PriceLineId))),
      ];
      priceLines.forEach((line) => {
        const descriptionItem = prices.filter(
          (price) => parseInt(price.PriceLineId) === line
        )[0];
        const priceTypeItem = prices.filter(
          (price) => parseInt(price.PriceLineId) === line
        )[0];
        const costPriceItem = prices.filter(
          (price) =>
            parseInt(price.PriceLineId) === line &&
            costId === parseInt(price.QuoteTypeId)
        )[0];
        const opexPriceItem = prices.filter(
          (price) =>
            parseInt(price.PriceLineId) === line &&
            opexId === parseInt(price.QuoteTypeId)
        )[0];
        const capexPriceItem = prices.filter(
          (price) =>
            parseInt(price.PriceLineId) === line &&
            capexId === parseInt(price.QuoteTypeId)
        )[0];
        const qantasPriceItem = prices.filter(
          (price) =>
            parseInt(price.PriceLineId) === line &&
            qantasId === parseInt(price.QuoteTypeId)
        )[0];
        const enerdsPriceItem = prices.filter(
          (price) =>
            parseInt(price.PriceLineId) === line &&
            enerdsId === parseInt(price.QuoteTypeId)
        )[0];
        const qantasPointsApplicableItem = prices.filter(
          (price) => parseInt(price.PriceLineId) === line
        )[0];

        transformedProductPrices.push({
          ProductId: data.Product.ProductId,
          PriceLineId: line,
          Description: descriptionItem ? descriptionItem.Description : '',
          PriceType: priceTypeItem ? priceTypeItem.PriceType : '',
          CostPrice: costPriceItem ? costPriceItem.Price : 0,
          Multiplier: capexPriceItem
            ? capexPriceItem.Multiplier
            : quoteTypes.CAPEX.DefaultMultiplier,

          CapexPrice: capexPriceItem ? capexPriceItem.Price : 0,
          isDisplayedCapexPrice: capexPriceItem
            ? capexPriceItem.isDisplayedPrice
            : 0,
          DisplayProductCapexPrice: capexPriceItem
            ? capexPriceItem.DisplayProduct
            : 0,
          IncludePriceInTotalCapexPrice: capexPriceItem
            ? capexPriceItem.IncludePriceInTotal
            : 0,

          OpexPrice: opexPriceItem ? opexPriceItem.Price : 0,
          isDisplayedOpexPrice: opexPriceItem
            ? opexPriceItem.isDisplayedPrice
            : 0,
          DisplayProductOpexPrice: opexPriceItem
            ? opexPriceItem.DisplayProduct
            : 0,
          IncludePriceInTotalOpexPrice: opexPriceItem
            ? opexPriceItem.IncludePriceInTotal
            : 0,

          EnerdsPrice: enerdsPriceItem ? enerdsPriceItem.Price : 0,
          isDisplayedEnerdsPrice: enerdsPriceItem
            ? enerdsPriceItem.isDisplayedPrice
            : 0,
          DisplayProductEnerdsPrice: enerdsPriceItem
            ? enerdsPriceItem.DisplayProduct
            : 0,
          IncludePriceInTotalEnerdsPrice: enerdsPriceItem
            ? enerdsPriceItem.IncludePriceInTotal
            : 0,

          QantasPrice: qantasPriceItem ? qantasPriceItem.Price : 0,
          QantasPointsApplicable: qantasPointsApplicableItem
            ? qantasPointsApplicableItem.QantasPointsApplicable
            : 0,
          isDisplayedQantasPrice: qantasPriceItem
            ? qantasPriceItem.isDisplayedPrice
            : 0,
          DisplayProductQantasPrice: qantasPriceItem
            ? qantasPriceItem.DisplayProduct
            : 0,
          IncludePriceInTotalQantasPrice: qantasPriceItem
            ? qantasPriceItem.IncludePriceInTotal
            : 0,
        });
      });
    }
    return transformedProductPrices;
  };

  const transformToProductPricesDBData = () => {
    const quoteTypes = Array.from(data.QuoteTypes);
    let transformedProductPrices = [];
    productPrices.forEach((price) => {
      quoteTypes.forEach((quoteType) => {
        transformedProductPrices.push({
          ProductId:
            data.Product && data.Product.ProductId
              ? data.Product.ProductId
              : productData.ProductId,
          PriceLineId: price.PriceLineId,
          PriceType: price.PriceType,
          Price:
            quoteType.Name === 'COST_PRICE'
              ? price['CostPrice']
                ? price['CostPrice']
                : 0
              : price[`${capitalizeStr(quoteType.Name)}Price`]
                ? price[`${capitalizeStr(quoteType.Name)}Price`]
                : 0,
          Multiplier:
            quoteType.Name === 'CAPEX'
              ? price['Multiplier']
              : quoteType.DefaultMultiplier,
          QuoteTypeId: quoteType.QuoteTypeId,
          Description: price.Description,
          QantasPointsApplicable: price.QantasPointsApplicable,
          isDisplayedPrice: (() => {
            if (quoteType.Name === 'CAPEX') {
              return price.isDisplayedCapexPrice;
            } else if (quoteType.Name === 'OPEX') {
              return price.isDisplayedOpexPrice;
            } else if (quoteType.Name === 'ENERDS') {
              return price.isDisplayedEnerdsPrice;
            } else if (quoteType.Name === 'QANTAS') {
              return price.isDisplayedQantasPrice;
            } else {
              return 0;
            }
          })(),
          DisplayProduct: (() => {
            if (quoteType.Name === 'CAPEX') {
              return price.DisplayProductCapexPrice;
            } else if (quoteType.Name === 'OPEX') {
              return price.DisplayProductOpexPrice;
            } else if (quoteType.Name === 'ENERDS') {
              return price.DisplayProductEnerdsPrice;
            } else if (quoteType.Name === 'QANTAS') {
              return price.DisplayProductQantasPrice;
            } else {
              return 0;
            }
          })(),
          IncludePriceInTotal: (() => {
            if (quoteType.Name === 'CAPEX') {
              return price.IncludePriceInTotalCapexPrice;
            } else if (quoteType.Name === 'OPEX') {
              return price.IncludePriceInTotalOpexPrice;
            } else if (quoteType.Name === 'ENERDS') {
              return price.IncludePriceInTotalEnerdsPrice;
            } else if (quoteType.Name === 'QANTAS') {
              return price.IncludePriceInTotalQantasPrice;
            } else {
              return 0;
            }
          })(),
        });
      });
    });

    return transformedProductPrices;
  };

  // PRODUCT RATES

  const updateRateEntry = (value, index, field) => {
    const editRate = Object.assign({}, productRates[index]);
    editRate[field] = value;

    const newRates = Array.from(productRates);
    newRates[index] = editRate;
    setProductRates(newRates);
  };

  const onNewRateChange = (value, field) => {
    const rate = Object.assign({}, newRate);
    rate[field] = value;
    setNewRate(rate);
  };

  const onDeleteRate = (index) => {
    const newRates = Array.from(productRates);
    newRates.splice(index, 1);
    setProductRates(newRates);
  };

  const addProductRate = (e) => {
    e.preventDefault();
    const rate = Array.from(productRates);
    newRate.RateLineId =
      productRates.length > 0 ? parseInt(productRates.pop().RateLineId) + 1 : 1;
    rate.push(newRate);
    setProductRates(rate);
    setNewRate({
      RateType: RATE_TYPES[0].value,
      OpexRate: '',
      CapexRate: '',
      QantasRate: '',
      EnerdsRate: '',
      RateUnits: "",
    });
  };

  const transformDBDataToProductRates = () => {
    let transformedProductRates = [];
    if (data && data.Product && data.Product.ProductRates.length > 0) {
      const rates = data.Product.ProductRates;
      const opexId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'OPEX')[0]
          .QuoteTypeId
      );
      const capexId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'CAPEX')[0]
          .QuoteTypeId
      );
      const qantasId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'QANTAS')[0]
          .QuoteTypeId
      );
      const enerdsId = parseInt(
        data.QuoteTypes.filter((quoteType) => quoteType.Name === 'ENERDS')[0]
          .QuoteTypeId
      );

      const rateLines = [
        ...new Set(rates.map((rate) => parseInt(rate.RateLineId))),
      ];
      rateLines.forEach((line) => {
        transformedProductRates.push({
          ProductId: data.Product.ProductId,
          RateLineId: line,
          RateType: rates.filter(
            (rate) => parseInt(rate.RateLineId) === line
          )[0].RateType,
          OpexRate: rates.filter(
            (rate) =>
              parseInt(rate.RateLineId) === line &&
              opexId === parseInt(rate.QuoteTypeId)
          )[0].Rate,
          CapexRate: rates.filter(
            (rate) =>
              parseInt(rate.RateLineId) === line &&
              capexId === parseInt(rate.QuoteTypeId)
          )[0].Rate,
          QantasRate: rates.filter(
            (rate) =>
              parseInt(rate.RateLineId) === line &&
              qantasId === parseInt(rate.QuoteTypeId)
          )[0].Rate,
          EnerdsRate: rates.filter(
            (rate) =>
              parseInt(rate.RateLineId) === line &&
              enerdsId === parseInt(rate.QuoteTypeId)
          )[0].Rate,
          RateUnits: rates.filter(
            (rate) => parseInt(rate.RateLineId) === line
          )[0].RateUnits,
        });
      });
    }
    return transformedProductRates;
  };

  const transformToProductRatesDBData = () => {
    const quoteTypes = Array.from(data.QuoteTypes).slice(1);
    let transformedProductRates = [];
    productRates.forEach((rate) => {
      quoteTypes.forEach((quoteType) => {
        transformedProductRates.push({
          RateLineId: rate.RateLineId,
          ProductId:
            data.Product && data.Product.ProductId
              ? data.Product.ProductId
              : productData.ProductId,
          RateType: rate.RateType,
          RateUnits: rate.RateUnits,
          Rate: rate[`${capitalizeStr(quoteType.Name)}Rate`]
            ? rate[`${capitalizeStr(quoteType.Name)}Rate`]
            : 0,
          QuoteTypeId: quoteType.QuoteTypeId,
        });
      });
    });
    return transformedProductRates;
  };

  const saveProduct = () => {
    if (productData.Name && productData.ProductId && productData.CatId) {
      if (formType === 'create') {
        createProduct({
          variables: {
            ProductInput: productData,
          },
        });
      } else {
        const product = Object.assign({}, productData);
        product.ProductId = data.Product.ProductId;
        updateProduct({
          variables: {
            ProductInput: product,
          },
        });
      }

      createProductPrices({
        variables: {
          ProductPricesList: transformToProductPricesDBData(),
          ProductId: productData.ProductId,
        },
      });

      createProductRates({
        variables: {
          ProductRatesList: transformToProductRatesDBData(),
          ProductId: productData.ProductId,
        },
      });
    } else {
      setFormResult({
        success: false,
        message: 'Product ID, Name and Category is required.',
      });
    }
  };

  return (
    <React.Fragment>
      {formResult && formResult.success && (
        <SweetAlert
          success
          title={<span style={{ fontSize: '24px' }}>Success</span>}
          onConfirm={(e) =>
            (window.location.href = `/admin/products${currentQuoteParam}`)
          }
          showConfirm={false}
          timeout={2500}
        >
          {formResult.message}
        </SweetAlert>
      )}
      {formResult && formResult.success === false && (
        <SweetAlert
          error
          title={<span style={{ fontSize: '24px' }}>Error</span>}
          onConfirm={(e) => setFormResult(null)}
          showConfirm={true}
        >
          {formResult.message}
        </SweetAlert>
      )}
      {data &&
        ((data.Product === null && formType === 'create') ||
          (data.Product && formType === 'edit')) &&
        qantasType && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="12">
                    {data && data.Product ? (
                      <h4 className="page-title">{`Edit Product - ${data.Product.Name}`}</h4>
                    ) : (
                      <h4 className="page-title">{`Create Product`}</h4>
                    )}
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardHeader>Product Details</CardHeader>
                    <CardBody>
                      <Form>
                        <FormGroup row>
                          <Label sm={6}>Product Id</Label>
                          <Col sm={6} className="text-right">
                            <Input
                              type="text"
                              maxLength={99}
                              defaultValue={
                                data.Product && data.Product.ProductId
                                  ? data.Product.ProductId
                                  : ''
                              }
                              onChange={(e) =>
                                productDetailsChange(e, 'ProductId')
                              }
                              disabled={formType === 'edit' ? true : false}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Product Name</Label>
                          <Col sm={6} className="text-right">
                            <Input
                              type="text"
                              maxLength={200}
                              defaultValue={
                                data.Product && data.Product.Name
                                  ? data.Product.Name
                                  : ''
                              }
                              onChange={(e) => productDetailsChange(e, 'Name')}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Product Category</Label>
                          <Col sm={6}>
                            <Select
                              placeholder={'Select Category'}
                              options={data.ProductCategories.map((cat) => ({
                                value: cat.CatId,
                                label: cat.Name,
                              }))}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) => productDetailsChange(e, 'CatId')}
                              defaultValue={
                                data.Product && data.Product.ProductCategory
                                  ? {
                                    value: data.Product.ProductCategory.CatId,
                                    label: data.Product.ProductCategory.Name,
                                  }
                                  : null
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Sync with Xero</Label>
                          <Col sm={6}>
                            <input
                              type="checkbox"
                              checked={productData && productData.SyncWithXero}
                              onChange={(e) =>
                                productDetailsChange(
                                  e.target.checked ? 1 : 0,
                                  'SyncWithXero'
                                )
                              }
                              disabled={
                                productData && productData.ArchivedInXero
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Archived in Xero</Label>
                          <Col sm={6}>
                            <input
                              type="checkbox"
                              defaultChecked={
                                data.Product && data.Product.ArchivedInXero
                              }
                              onChange={(e) =>
                                productDetailsChange(
                                  e.target.checked ? 1 : 0,
                                  'ArchivedInXero'
                                )
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={6}>
                            Shown in "Hardware Items" table proposals
                          </Label>
                          <Col sm={6}>
                            <input
                              type="checkbox"
                              defaultChecked={
                                (data.Product &&
                                  data.Product.ShownInHardwareTable) ||
                                (formType.toLowerCase() === 'create' &&
                                  productData.ShownInHardwareTable)
                              }
                              onChange={(e) =>
                                productDetailsChange(
                                  e.target.checked ? 1 : 0,
                                  'ShownInHardwareTable'
                                )
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={6}>
                            Jarvis "Magic Pricing"
                            <small className="d-block">
                              Calculate monthly recurring prices (on OPEX
                              pricing models) as a multiple of hardware CAPEX
                              prices.
                            </small>
                            <small className="d-block">
                              (eg. OPEX quote Monthly fees = CAPEX costs / 24 x
                              Quote Type Multiplier)
                            </small>
                          </Label>
                          <Col sm={6}>
                            <div className="d-flex align-items-center h-100">
                              <input
                                type="checkbox"
                                defaultChecked={
                                  data.Product &&
                                  data.Product.JarvisMagicPricing
                                }
                                onChange={(e) =>
                                  productDetailsChange(
                                    e.target.checked ? 1 : 0,
                                    'JarvisMagicPricing'
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Xero Tracked as Inventory</Label>
                          <Col sm={6}>
                            <input
                              type="checkbox"
                              defaultChecked={
                                data.Product &&
                                data.Product.XeroTrackedAsInventory
                              }
                              onChange={(e) =>
                                productDetailsChange(
                                  e.target.checked ? 1 : 0,
                                  'XeroTrackedAsInventory'
                                )
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>
                            Xero Inventory Asset Account Code
                          </Label>
                          <Col sm={6}>
                            <Input
                              type="number"
                              defaultValue={
                                data.Product &&
                                  data.Product.XeroInventoryAssetAccountCode
                                  ? data.Product.XeroInventoryAssetAccountCode
                                  : ''
                              }
                              onChange={(e) =>
                                productDetailsChange(
                                  e,
                                  'XeroInventoryAssetAccountCode'
                                )
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Xero Chart of Accounts (Buy)</Label>
                          <Col sm={6}>
                            <Select
                              placeholder={'Select Xero Account'}
                              options={data.XeroCOA.map((coa) => ({
                                value: coa.XeroCOAId,
                                label: coa.Account,
                              }))}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) =>
                                productDetailsChange(e, 'XeroCOA')
                              }
                              defaultValue={
                                data.Product && data.Product.XeroCOA
                                  ? {
                                    value: data.Product.XeroCOA,
                                    label: data.Product.XeroAccount.Account,
                                  }
                                  : null
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Xero Chart of Accounts (Sell)</Label>
                          <Col sm={6}>
                            <Select
                              placeholder={'Select Xero Account for Sales'}
                              options={data.XeroCOA.map((coa) => ({
                                value: coa.XeroCOAId,
                                label: coa.Account,
                              }))}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) =>
                                productDetailsChange(e, 'XeroCOASales')
                              }
                              defaultValue={
                                data.Product && data.Product.XeroCOASales
                                  ? {
                                    value: data.Product.XeroCOASales,
                                    label:
                                      data.Product.XeroAccountSales.Account,
                                  }
                                  : null
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Product Weight (grams)</Label>
                          <Col sm={6}>
                            <Input
                              type="number"
                              defaultValue={
                                data.Product && data.Product.WeightGrams
                                  ? data.Product.WeightGrams
                                  : ''
                              }
                              onChange={(e) =>
                                productDetailsChange(e, 'WeightGrams')
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={6}>Active</Label>
                          <Col sm={6}>
                            <input
                              type="checkbox"
                              checked={
                                (productData && productData.Active) ||
                                (formType.toLowerCase() === 'create' &&
                                  productData.Active)
                              }
                              onChange={(e) =>
                                productDetailsChange(
                                  e.target.checked ? 1 : 0,
                                  'Active'
                                )
                              }
                              disabled={
                                productData && productData.ArchivedInXero
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>Zapier event when Quoted:</Label>
                          <Col sm={6}>
                            <Select
                              placeholder={'Select zapier event when quoted'}
                              options={zapEventsOptions}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) =>
                                productDetailsChange(e, 'ZapierQuoted')
                              }
                              defaultValue={
                                data.Product && data.Product.ZapierQuoted
                                  ? {
                                    value: data.Product.ZapierQuoted,
                                    label:
                                      data.Product.ZapierQuotedEvent.ZapName,
                                  }
                                  : { value: null, label: 'No Zap' }
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>
                            Zapier event when Ordered as a new service:
                          </Label>
                          <Col sm={6}>
                            <Select
                              placeholder={
                                'Select zapier event when Ordered as a new service'
                              }
                              options={zapEventsOptions}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) =>
                                productDetailsChange(e, 'ZapierOrdered')
                              }
                              defaultValue={
                                data.Product && data.Product.ZapierOrdered
                                  ? {
                                    value: data.Product.Ordered,
                                    label:
                                      data.Product.ZapierOrderedEvent.ZapName,
                                  }
                                  : { value: null, label: 'No Zap' }
                              }
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={6}>
                            Zapier event when Ordered as a modification:
                          </Label>
                          <Col sm={6}>
                            <Select
                              placeholder={
                                'Select zapier event when Ordered as a modification'
                              }
                              options={zapEventsOptions}
                              menuPortalTarget={document.querySelector('body')}
                              onChange={(e) =>
                                productDetailsChange(e, 'ZapierModified')
                              }
                              defaultValue={
                                data.Product && data.Product.ZapierModified
                                  ? {
                                    value: data.Product.ZapierModified,
                                    label:
                                      data.Product.ZapierModifiedEvent
                                        .ZapName,
                                  }
                                  : { value: null, label: 'No Zap' }
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </CardBody>
                  </Card>

                  <h6>Product Pricing</h6>
                  <Card>
                    <CardBody className="p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th colSpan={1}></th>
                              <th colSpan={1}>Charge as </th>
                              <th colSpan={6} className="text-center">
                                For this Quote Type
                              </th>
                              <th colSpan={1}></th>
                            </tr>
                            <tr>
                              {PRODUCT_PRICING_COLUMNS.map((column) =>
                                qantasType.Active === 0 && column.id === 7 ? (
                                  ''
                                ) : (
                                  <ColumnHeadersTooltip
                                    key={column.id}
                                    id={'product-pricing'}
                                    column={column}
                                  />
                                )
                              )}
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {productPrices.length > 0 ? (
                              productPrices.map((price, index) => (
                                <tr key={index}>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="text"
                                      placeholder="Line Description (defaults to product name)"
                                      style={{ width: '300px' }}
                                      value={
                                        price.Description
                                          ? price.Description
                                          : ''
                                      }
                                      onChange={(e) =>
                                        updatePriceEntry(
                                          e.target.value,
                                          index,
                                          'Description'
                                        )
                                      }
                                    />
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <div style={{ width: '180px' }}>
                                      <Select
                                        options={PRICE_TYPES.map(
                                          (priceType) => ({
                                            value: priceType.value,
                                            label: priceType.label,
                                          })
                                        )}
                                        menuPortalTarget={document.querySelector(
                                          'body'
                                        )}
                                        value={
                                          PRICE_TYPES.filter(
                                            (priceType) =>
                                              priceType.value ===
                                              price.PriceType
                                          ).map((priceType) => ({
                                            value: priceType.value,
                                            label: priceType.label,
                                          }))[0]
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.value,
                                            index,
                                            'PriceType'
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  {parseInt(env.QANTAS_POINTS_ENABLED) ===
                                    1 && (
                                      <td className="text-center">
                                        <input
                                          type="checkbox"
                                          style={{
                                            width: '100px',
                                            height: '20px',
                                          }}
                                          checked={price.QantasPointsApplicable}
                                          onChange={(e) =>
                                            updatePriceEntry(
                                              e.target.checked ? 1 : 0,
                                              index,
                                              'QantasPointsApplicable'
                                            )
                                          }
                                          disabled={price.PriceType !== 'monthly'}
                                        />
                                      </td>
                                    )}
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={
                                        price.CostPriceStyle
                                          ? price.CostPriceStyle
                                          : { width: '100px' }
                                      }
                                      value={
                                        productData.JarvisMagicPricing &&
                                          price.PriceType === 'monthly'
                                          ? ''
                                          : price.CostPrice
                                      }
                                      onChange={(e) =>
                                        updatePriceEntry(
                                          e.target.value,
                                          index,
                                          'CostPrice'
                                        )
                                      }
                                      disabled={
                                        productData.JarvisMagicPricing &&
                                          price.PriceType === 'monthly'
                                          ? true
                                          : false
                                      }
                                    />
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    {productData.JarvisMagicPricing ? (
                                      <Input
                                        type="number"
                                        style={
                                          price.MultiplierStyle
                                            ? price.MultiplierStyle
                                            : { width: '100px' }
                                        }
                                        value={
                                          productData.JarvisMagicPricing &&
                                            price.PriceType === 'monthly'
                                            ? ''
                                            : price.Multiplier
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.value,
                                            index,
                                            'Multiplier'
                                          )
                                        }
                                        disabled={
                                          productData.JarvisMagicPricing &&
                                            price.PriceType === 'monthly'
                                            ? true
                                            : false
                                        }
                                      />
                                    ) : (
                                      <span className="not-applicable">
                                        Not applicable
                                      </span>
                                    )}
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={
                                        price.CapexPriceStyle
                                          ? price.CapexPriceStyle
                                          : { width: '100px' }
                                      }
                                      value={
                                        productData.JarvisMagicPricing &&
                                          price.PriceType === 'monthly'
                                          ? ''
                                          : price.CapexPrice
                                      }
                                      onChange={(e) =>
                                        updatePriceEntry(
                                          e.target.value,
                                          index,
                                          'CapexPrice'
                                        )
                                      }
                                      disabled={
                                        productData.JarvisMagicPricing &&
                                          price.PriceType === 'monthly'
                                          ? true
                                          : false
                                      }
                                    />
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'isDisplayedCapexPrice' + index}
                                        defaultChecked={
                                          price.isDisplayedCapexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'isDisplayedCapexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'isDisplayedCapexPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Display Price</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'DisplayProductCapexPrice' + index}
                                        defaultChecked={
                                          price.DisplayProductCapexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'DisplayProductCapexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'DisplayProductCapexPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Display Product</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={
                                          'IncludePriceInTotalCapexPrice' +
                                          index
                                        }
                                        defaultChecked={
                                          price.IncludePriceInTotalCapexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'IncludePriceInTotalCapexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'IncludePriceInTotalCapexPrice' +
                                          index
                                        }
                                        className="m-1"
                                      >
                                        <small>Include Price in Total</small>
                                      </label>
                                    </div>
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={
                                        price.OpexPriceStyle
                                          ? price.OpexPriceStyle
                                          : { width: '100px' }
                                      }
                                      value={price.OpexPrice}
                                      onChange={(e) =>
                                        updatePriceEntry(
                                          e.target.value,
                                          index,
                                          'OpexPrice'
                                        )
                                      }
                                      disabled={productData.JarvisMagicPricing}
                                    />
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'isDisplayedOpexPrice' + index}
                                        defaultChecked={
                                          price.isDisplayedOpexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'isDisplayedOpexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={'isDisplayedOpexPrice' + index}
                                        className="m-1"
                                      >
                                        <small>Display Price</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'DisplayProductOpexPrice' + index}
                                        defaultChecked={
                                          price.DisplayProductOpexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'DisplayProductOpexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'DisplayProductOpexPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Display Product</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={
                                          'IncludePriceInTotalOpexPrice' + index
                                        }
                                        defaultChecked={
                                          price.IncludePriceInTotalOpexPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'IncludePriceInTotalOpexPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'IncludePriceInTotalOpexPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Include Price in Total</small>
                                      </label>
                                    </div>
                                  </td>
                                  {qantasType.Active === 1 && (
                                    <td style={{ verticalAlign: 'text-top' }}>
                                      <Input
                                        type="number"
                                        style={{ width: '100px' }}
                                        value={price.QantasPrice}
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.value,
                                            index,
                                            'QantasPrice'
                                          )
                                        }
                                        disabled={
                                          productData.JarvisMagicPricing
                                        }
                                      />
                                      <div className="mt-1 d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          id={'isDisplayedQantasPrice' + index}
                                          defaultChecked={
                                            price.isDisplayedQantasPrice
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            updatePriceEntry(
                                              e.target.checked ? 1 : 0,
                                              index,
                                              'isDisplayedQantasPrice'
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={
                                            'isDisplayedQantasPrice' + index
                                          }
                                          className="m-1"
                                        >
                                          <small>Display Price</small>
                                        </label>
                                      </div>
                                      <div className="mt-1 d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          id={
                                            'DisplayProductQantasPrice' + index
                                          }
                                          defaultChecked={
                                            price.isDisplayedQantasPrice
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            updatePriceEntry(
                                              e.target.checked ? 1 : 0,
                                              index,
                                              'DisplayProductQantasPrice'
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={
                                            'DisplayProductQantasPrice' + index
                                          }
                                          className="m-1"
                                        >
                                          <small>Display Product</small>
                                        </label>
                                      </div>
                                      <div className="mt-1 d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          id={
                                            'IncludePriceInTotalQantasPrice' +
                                            index
                                          }
                                          defaultChecked={
                                            price.IncludePriceInTotalQantasPrice
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            updatePriceEntry(
                                              e.target.checked ? 1 : 0,
                                              index,
                                              'IncludePriceInTotalQantasPrice'
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={
                                            'IncludePriceInTotalQantasPrice' +
                                            index
                                          }
                                          className="m-1"
                                        >
                                          <small>Include Price in Total</small>
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={
                                        price.EnerdsPriceStyle
                                          ? price.EnerdsPriceStyle
                                          : { width: '100px' }
                                      }
                                      value={price.EnerdsPrice}
                                      onChange={(e) =>
                                        updatePriceEntry(
                                          e.target.value,
                                          index,
                                          'EnerdsPrice'
                                        )
                                      }
                                      disabled={productData.JarvisMagicPricing}
                                    />
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'isDisplayedEnerdsPrice' + index}
                                        defaultChecked={
                                          price.isDisplayedEnerdsPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'isDisplayedEnerdsPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'isDisplayedEnerdsPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Display Price</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={'DisplayProductEnerdsPrice' + index}
                                        defaultChecked={
                                          price.DisplayProductEnerdsPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'DisplayProductEnerdsPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'DisplayProductEnerdsPrice' + index
                                        }
                                        className="m-1"
                                      >
                                        <small>Display Product</small>
                                      </label>
                                    </div>
                                    <div className="mt-1 d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        id={
                                          'IncludePriceInTotalEnerdsPrice' +
                                          index
                                        }
                                        defaultChecked={
                                          price.IncludePriceInTotalEnerdsPrice
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          updatePriceEntry(
                                            e.target.checked ? 1 : 0,
                                            index,
                                            'IncludePriceInTotalEnerdsPrice'
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={
                                          'IncludePriceInTotalEnerdsPrice' +
                                          index
                                        }
                                        className="m-1"
                                      >
                                        <small>Include Price in Total</small>
                                      </label>
                                    </div>
                                  </td>
                                  <td
                                    style={{
                                      width: '50px',
                                      verticalAlign: 'text-top',
                                    }}
                                  >
                                    <Button
                                      color="danger"
                                      onClick={(e) => onDeletePrice(index)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={8}>No Product Prices</td>
                              </tr>
                            )}

                            <tr>
                              <th>Item</th>
                              <th>Price Type</th>
                              {parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                                <th>Qantas Points</th>
                              )}
                              <th>Cost Quote</th>
                              <th>CAPEX Multiplier</th>
                              <th>CAPEX Quote</th>
                              <th>OPEX Quote</th>
                              {qantasType.Active === 1 && <th>QANTAS Quote</th>}
                              <th>ENERDS Quote</th>
                              <th></th>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="text"
                                  placeholder="Line Description (defaults to product name)"
                                  value={newPrice.Description}
                                  style={{ width: '300px' }}
                                  onChange={(e) =>
                                    onNewPriceChange(
                                      e.target.value,
                                      'Description'
                                    )
                                  }
                                />
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <div style={{ width: '180px' }}>
                                  <Select
                                    options={PRICE_TYPES.map((priceType) => ({
                                      value: priceType.value,
                                      label: priceType.label,
                                    }))}
                                    value={
                                      PRICE_TYPES.filter(
                                        (priceType) =>
                                          priceType.value === newPrice.PriceType
                                      ).map((priceType) => ({
                                        value: priceType.value,
                                        label: priceType.label,
                                      }))[0]
                                    }
                                    menuPortalTarget={document.querySelector(
                                      'body'
                                    )}
                                    defaultValue={null}
                                    onChange={(e) =>
                                      onNewPriceChange(e.value, 'PriceType')
                                    }
                                  />
                                </div>
                              </td>
                              {parseInt(env.QANTAS_POINTS_ENABLED) === 1 && (
                                <td className="text-center">
                                  <input
                                    type="checkbox"
                                    style={{ width: '100px', height: '20px' }}
                                    checked={
                                      newPrice.PriceType === 'monthly'
                                        ? 1
                                        : newPrice.QantasPointsApplicable
                                    }
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'QantasPointsApplicable'
                                      )
                                    }
                                    disabled={newPrice.PriceType !== 'monthly'}
                                  />
                                </td>
                              )}
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  style={
                                    newPrice.CostPriceStyle
                                      ? newPrice.CostPriceStyle
                                      : { width: '100px' }
                                  }
                                  value={newPrice.CostPrice}
                                  onChange={(e) =>
                                    onNewPriceChange(
                                      e.target.value,
                                      'CostPrice'
                                    )
                                  }
                                />
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                {productData.JarvisMagicPricing ? (
                                  <Input
                                    type="number"
                                    style={
                                      newPrice.MultiplierStyle
                                        ? newPrice.MultiplierStyle
                                        : { width: '100px' }
                                    }
                                    value={newPrice.Multiplier}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.value,
                                        'Multiplier'
                                      )
                                    }
                                  />
                                ) : (
                                  <span className="not-applicable">
                                    Not applicable
                                  </span>
                                )}
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  style={
                                    newPrice.CapexPriceStyle
                                      ? newPrice.CapexPriceStyle
                                      : { width: '100px' }
                                  }
                                  value={newPrice.CapexPrice}
                                  onChange={(e) =>
                                    onNewPriceChange(
                                      e.target.value,
                                      'CapexPrice'
                                    )
                                  }
                                />
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'isDisplayedCapexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'isDisplayedCapexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'isDisplayedCapexPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Price</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'DisplayProductCapexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'DisplayProductCapexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'DisplayProductCapexPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Product</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'IncludePriceInTotalCapexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'IncludePriceInTotalCapexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'IncludePriceInTotalCapexPrice'}
                                    className="m-1"
                                  >
                                    <small>Include Price in Total</small>
                                  </label>
                                </div>
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  style={
                                    newPrice.OpexPriceStyle
                                      ? newPrice.OpexPriceStyle
                                      : { width: '100px' }
                                  }
                                  value={newPrice.OpexPrice}
                                  onChange={(e) =>
                                    onNewPriceChange(
                                      e.target.value,
                                      'OpexPrice'
                                    )
                                  }
                                  disabled={productData.JarvisMagicPricing}
                                />
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'isDisplayedOpexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'isDisplayedOpexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'isDisplayedOpexPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Price</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'DisplayProductOpexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'DisplayProductOpexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'DisplayProductOpexPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Product</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'IncludePriceInTotalOpexPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'IncludePriceInTotalOpexPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'IncludePriceInTotalOpexPrice'}
                                    className="m-1"
                                  >
                                    <small>Include Price in Total</small>
                                  </label>
                                </div>
                              </td>
                              {qantasType.Active === 1 && (
                                <td style={{ verticalAlign: 'text-top' }}>
                                  <Input
                                    type="number"
                                    style={
                                      newPrice.QantasPriceStyle
                                        ? newPrice.QantasPriceStyle
                                        : { width: '100px' }
                                    }
                                    value={newPrice.QantasPrice}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.value,
                                        'QantasPrice'
                                      )
                                    }
                                  />
                                </td>
                              )}
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  style={
                                    newPrice.EnerdsPriceStyle
                                      ? newPrice.EnerdsPriceStyle
                                      : { width: '100px' }
                                  }
                                  value={newPrice.EnerdsPrice}
                                  onChange={(e) =>
                                    onNewPriceChange(
                                      e.target.value,
                                      'EnerdsPrice'
                                    )
                                  }
                                  disabled={productData.JarvisMagicPricing}
                                />
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'isDisplayedEnerdsPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'isDisplayedEnerdsPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'isDisplayedEnerdsPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Price</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'DisplayProductEnerdsPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'DisplayProductEnerdsPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'DisplayProductEnerdsPrice'}
                                    className="m-1"
                                  >
                                    <small>Display Product</small>
                                  </label>
                                </div>
                                <div className="mt-1 d-flex align-items-center">
                                  <input
                                    type="checkbox"
                                    id={'IncludePriceInTotalEnerdsPrice'}
                                    onChange={(e) =>
                                      onNewPriceChange(
                                        e.target.checked ? 1 : 0,
                                        'IncludePriceInTotalEnerdsPrice'
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor={'IncludePriceInTotalEnerdsPrice'}
                                    className="m-1"
                                  >
                                    <small>Include Price in Total</small>
                                  </label>
                                </div>
                              </td>
                              <td
                                style={{
                                  width: '50px',
                                  verticalAlign: 'text-top',
                                }}
                              >
                                <Button
                                  color="success"
                                  block
                                  onClick={addProductPrice}
                                >
                                  Add
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>

                  {/* PRODUCT RATES */}

                  <h6>Product Rates</h6>
                  <Card>
                    <CardBody className="p-0">
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Rate Type</th>
                              <th>OPEX Rate</th>
                              <th>CAPEX Rate</th>
                              {qantasType.Active === 1 && <th>QANTAS Rate</th>}
                              <th>ENERDS Rate</th>
                              <th>Rate Units</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {productRates.length > 0 ? (
                              productRates.map((rate, index) => (
                                <tr key={index}>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <div style={{ width: '180px' }}>
                                      <Select
                                        options={RATE_TYPES.map((rateType) => ({
                                          value: rateType.value,
                                          label: rateType.label,
                                        }))}
                                        menuPortalTarget={document.querySelector(
                                          'body'
                                        )}
                                        value={
                                          RATE_TYPES.filter(
                                            (rateType) =>
                                              rateType.value === rate.RateType
                                          ).map((rateType) => ({
                                            value: rateType.value,
                                            label: rateType.label,
                                          }))[0]
                                        }
                                        onChange={(e) =>
                                          updateRateEntry(
                                            e.value,
                                            index,
                                            'RateType'
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={{ width: '100px' }}
                                      value={rate.OpexRate}
                                      onChange={(e) =>
                                        updateRateEntry(
                                          e.target.value,
                                          index,
                                          'OpexRate'
                                        )
                                      }
                                    />
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={{ width: '100px' }}
                                      value={rate.CapexRate}
                                      onChange={(e) =>
                                        updateRateEntry(
                                          e.target.value,
                                          index,
                                          'CapexRate'
                                        )
                                      }
                                    />
                                  </td>
                                  {qantasType.Active === 1 && (
                                    <td style={{ verticalAlign: 'text-top' }}>
                                      <Input
                                        type="number"
                                        style={{ width: '100px' }}
                                        value={rate.QantasRate}
                                        onChange={(e) =>
                                          updateRateEntry(
                                            e.target.value,
                                            index,
                                            'QantasRate'
                                          )
                                        }
                                      />
                                    </td>
                                  )}
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <Input
                                      type="number"
                                      style={{ width: '100px' }}
                                      value={rate.EnerdsRate}
                                      onChange={(e) =>
                                        updateRateEntry(
                                          e.target.value,
                                          index,
                                          'EnerdsRate'
                                        )
                                      }
                                    />
                                  </td>
                                  <td style={{ verticalAlign: 'text-top' }}>
                                    <div style={{ width: '180px' }}>
                                      <Input
                                        type="text"
                                        style={{ width: '100px' }}
                                        onChange={(e) =>
                                          updateRateEntry(
                                            e.target.value,
                                            index,
                                            'RateUnits'
                                          )
                                        }

                                        value={rate.RateUnits}
                                      />
                                      {/* <Select
                                        options={RATE_UNITS.map((rateUnit) => ({
                                          value: rateUnit.value,
                                          label: rateUnit.label,
                                        }))}
                                        menuPortalTarget={document.querySelector(
                                          'body'
                                        )}
                                        value={
                                          RATE_UNITS.filter(
                                            (rateUnit) =>
                                              rateUnit.value === rate.RateUnits
                                          ).map((rateUnit) => ({
                                            value: rateUnit.value,
                                            label: rateUnit.label,
                                          }))[0]
                                        }
                                        onChange={(e) =>
                                          updateRateEntry(
                                            e.value,
                                            index,
                                            'RateUnits'
                                          )
                                        }
                                      /> */}
                                    </div>
                                  </td>
                                  <td style={{ width: '50px' }}>
                                    <Button
                                      color="danger"
                                      onClick={(e) => onDeleteRate(index)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className="text-center">
                                <td colSpan={8}>No Product Rates</td>
                              </tr>
                            )}
                            <tr>
                              <th>Rate Type</th>
                              <th>OPEX Rate</th>
                              <th>CAPEX Rate</th>
                              {qantasType.Active === 1 && <th>QANTAS Rate</th>}
                              <th>ENERDS Rate</th>
                              <th>Rate Units</th>
                              <th></th>
                            </tr>
                            <tr>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <div style={{ width: '180px' }}>
                                  <Select
                                    options={RATE_TYPES.map((rateType) => ({
                                      value: rateType.value,
                                      label: rateType.label,
                                    }))}
                                    menuPortalTarget={document.querySelector(
                                      'body'
                                    )}
                                    value={
                                      RATE_TYPES.filter(
                                        (rateType) =>
                                          rateType.value === newRate.RateType
                                      ).map((rateType) => ({
                                        value: rateType.value,
                                        label: rateType.label,
                                      }))[0]
                                    }
                                    onChange={(e) =>
                                      onNewRateChange(e.value, 'RateType')
                                    }
                                  />
                                </div>
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  value={newRate.OpexRate}
                                  style={{ width: '100px' }}
                                  onChange={(e) =>
                                    onNewRateChange(e.target.value, 'OpexRate')
                                  }
                                />
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  value={newRate.CapexRate}
                                  style={{ width: '100px' }}
                                  onChange={(e) =>
                                    onNewRateChange(e.target.value, 'CapexRate')
                                  }
                                />
                              </td>
                              {qantasType.Active === 1 && (
                                <td style={{ verticalAlign: 'text-top' }}>
                                  <Input
                                    type="number"
                                    value={newRate.QantasRate}
                                    style={{ width: '100px' }}
                                    onChange={(e) =>
                                      onNewRateChange(
                                        e.target.value,
                                        'QantasRate'
                                      )
                                    }
                                  />
                                </td>
                              )}
                              <td style={{ verticalAlign: 'text-top' }}>
                                <Input
                                  type="number"
                                  value={newRate.EnerdsRate}
                                  style={{ width: '100px' }}
                                  onChange={(e) =>
                                    onNewRateChange(
                                      e.target.value,
                                      'EnerdsRate'
                                    )
                                  }
                                />
                              </td>
                              <td style={{ verticalAlign: 'text-top' }}>
                                <div style={{ width: '180px' }}>
                                  <Input
                                    type="text"
                                    style={{ width: '100px' }}
                                    onChange={(e) =>
                                      onNewRateChange(
                                        e.target.value,
                                        'RateUnits'
                                      )
                                    }
                                    value={
                                      RATE_UNITS.filter(
                                        (rateUnit) =>
                                          rateUnit.value === newRate.RateUnits
                                      ).map((rateUnit) => rateUnit.value)[0]
                                    }
                                  />

                                  {/* <Select
                                    options={RATE_UNITS.map((rateUnit) => ({
                                      value: rateUnit.value,
                                      label: rateUnit.label,
                                    }))}
                                    menuPortalTarget={document.querySelector(
                                      'body'
                                    )}
                                    value={
                                      RATE_UNITS.filter(
                                        (rateUnit) =>
                                          rateUnit.value === newRate.RateUnits
                                      ).map((rateUnit) => ({
                                        value: rateUnit.value,
                                        label: rateUnit.label,
                                      }))[0]
                                    }
                                    onChange={(e) =>
                                      onNewRateChange(e.value, 'RateUnits')
                                    }
                                  /> */}
                                </div>
                              </td>
                              <td style={{ width: '50px' }}>
                                <Button
                                  color="success"
                                  block
                                  onClick={addProductRate}
                                >
                                  Add
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>

                  <div className="text-lg-right">
                    <Button color="primary" onClick={saveProduct}>
                      Save Product
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}

      {
        // when data is loaded and product id is not valid show product does not exists error UI
        data && data.Product === null && formType === 'edit' && (
          <div className="content">
            <div className="container-fluid">
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h4 className="page-title">Error loading this page</h4>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      Product with ID {props.pathArg} does not exists
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

export default connect(null, { activateAuthLayout })(ProductForm);
